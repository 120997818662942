import React, { Component } from 'react' // Import
import Container from 'react-bootstrap/Container'
import Header, { Item, Float, Foot, Slider } from '../components/main'
import { Helm } from '../components/header'
import { Modal, Row, Col, Alert, Button } from 'react-bootstrap'
import { cap } from '../params'
import '../style/style.css'
import '../style/sty.scss'
import logoig from '../assets/img/nasta/logoig.svg'
import burung from '../assets/img/nasta/burung.svg'
import bunga6 from '../assets/img/bunga6.png'
import AOS from 'aos';
import { gambar, pw } from '../params'
import { Timer } from '../components/timer'
import post from '../params/post'
import getData from '../params/getdata'
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css';
import logo from '../assets/img/logo.ico'
import moment from 'moment'
import "aos/dist/aos.css";
import styled from 'styled-components'
import 'bootstrap/dist/css/bootstrap.min.css';
import { CopyToClipboard } from 'react-copy-to-clipboard';
var x
if (typeof window !== `undefined`){
    x = window.matchMedia("(max-width: 900px)").matches
  }


let cmain = '#C0573B'
let black = 'rgb(38,38,38)'
let purple = '#820f44'
let id = 'farah-fathon'
let inisial_co = 'Fathon'
let inisial_ce = 'Farah'
let lengkap_co = 'Fathon Darmawan'
let lengkap_ce = 'Farah Sandi'
let bapak_co = 'Bpk. Kol. Inf. Darmanto'
let ibu_co = 'Ibu Gatit Sihnowati'
let bapak_ce = "Bpk. Ir. Hendi Putra S "
let ibu_ce = "Ibu Susanti Febriani"
let ig_co = "fathondarmawan"
let ig_ce = "farahsandi"

let foto_ce = pw(id, "ce.jpg")
let foto_co = pw(id, "co.jpg")
let waktunikah = "02/07/2021"

let modal = pw(id, "modal.jpg")
let openlogo = pw(id, "logo.png")

let gmaps = "https://maps.app.goo.gl/N3Cne96FRkSN5wMFA"
let gcalendar = 'https://calendar.google.com/event?action=TEMPLATE&tmeid=MHVzMTZka3VlaGJmcXBtYjQxY2xkcXVsNXAgYXJpZWZjNzJAbQ&tmsrc=ariefc72%40gmail.com'
let gmaps1 = "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3953.661085105068!2d114.00215231477767!3d-7.719464994435708!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0!2zN8KwNDMnMTAuMSJTIDExNMKwMDAnMTUuNiJF!5e0!3m2!1sid!2sid!4v1607223099284!5m2!1sid!2sid"



export default class Halo extends Component {
    constructor(props) {
        super(props);
        this.myRef = React.createRef()
        this.myRef = React.createRef()
        this.nama = React.createRef()
        this.alamat = React.createRef()
        this.pesan = React.createRef()

        this.state = {
            acara: [],
            days: '00',
            minutes: '00',
            hours: '00',
            seconds: '00',
            hide: true,
            hadir: true,
            err: [],
            submitted: '',
            sesi: 0,
            lgShow: false,
            comment:[]
        }
    }

    async componentDidMount() {
        try {
            AOS.init({
                // initialise with other settings
                duration: 2000
            });
            let comment = await getData(`kepada:"${id}"`)
            

            this.setState({ comment: comment.reverse() })
            console.log(comment)
        } catch (error) {
            console.log(error)
        }

    }

    play = () => {
        AOS.refresh()
        var snd = new Audio(pw(id,"music.mp3"));
        snd.type = 'audio/mp3';
        snd.play();
        this.setState({ hide: false })
        setTimeout(() => {
            var elmnt = document.getElementById('top');
            elmnt.scrollIntoView();
        }, 1000)
    }

    useQuery = () => {
        return new URLSearchParams(this.props.location.search);
    }
    handleSubmit = async () => {
        let err = []
        let local = localStorage.getItem('pesan')
        if (this.nama.current.value == "") {
            err.push('Nama tidak Boleh Kosong')
        }
        if (this.pesan.current.value == "") {
            err.push('Pesan tidak Boleh Kosong')
        }
        if (err.length == 0) {
            confirmAlert({
                message: local ? `Kami mendeteksi bahwa anda telah mengirimkan pesan \" ${local} \", apakah anda ingin mengirim pesan lagi?` : 'Yakin untuk Mengirim Pesan?',
                buttons: [
                    {
                        label: 'Yes',
                        onClick: async () => {
                            let send = await post(
                                ` dari: "${encodeURI(this.nama.current.value)}", hadir: "${this.state.hadir}", jumlahhadir: "${new Date()}", kepada: "${id}", pesan:"${encodeURI(this.pesan.current.value)}",alamat: ""`
                            )
                            if (send.status == 200) {
                                this.setState({ submitted: true })
                                localStorage.setItem('pesan', this.pesan.current.value)
                                this.nama.current.value = ''
                                this.pesan.current.value = ''
                            } else {
                                err.push('Koneksi Gagal')
                            }


                        }
                    },
                    {
                        label: 'No',
                        onClick: () => { }
                    }
                ]
            });
        } else {
            this.setState({ err: err, submitted: false })
        }
    }
    render() {
        let { hadir, days, hours, comment, lgShow, hide, submitted, err, sesi } = this.state
        let slider = []
        let slide = [
            "DSCF0046.jpg",
            "DSCF0110.jpg",
            "DSCF0124.jpg",
            "DSCF0156.jpg",
            "DSCF0177.jpg",
            "DSCF0227.jpg",
            "DSCF0294.jpg",
            "DSCF0296.jpg",
            "DSCF0379.jpg",
            "DSCF7766.jpg",
            "DSCF7787.jpg",
            "DSCF7795.jpg",
            "DSCF7829.jpg",
            "DSCF8010.jpg",
            "DSCF8028.jpg",
        ]
        slide.map(v => {
            slider.push(pw(id, v))
        })
        let query = this.useQuery().get('u');

        query = query ? cap(query) : ''
        const Containers = styled.div`
  @media(max-width:900px){
      background-position:55% 50%!important
  }

`;
        return (
            <>
                <Helm
                    title={`Undanganku - ${inisial_ce} & ${inisial_co}`}
                    desc="undangan digital berbasis website untuk berbagai kebutuhan acara"
                    logo={logo}
                    img={slider[0]}
                    url={`https://undanganku.me/${id}`}

                >
                    <link rel="preconnect" href="https://fonts.gstatic.com" />
                    <link href="https://fonts.googleapis.com/css2?family=Merienda:wght@400;700&display=swap" rel="stylesheet" />
                    <link href="https://fonts.googleapis.com/css2?family=PT+Sans+Narrow:wght@400;700&display=swap" rel="stylesheet" />
                </Helm>

                <div id='gold5' style={{
                    backgroundImage: `url(${'https://i.ibb.co/gmz96rb/Background-Putih.jpg'})`,
                    backgroundSize: 'cover', backgroundPosition: 'center'
                }}>
                    {
                        this.useQuery().get('x') == "x" ? (<Float />) : false
                    }
                    <div className='w-100' style={{
                        overflow: 'hidden', maxWidth: '100vw',
                        backgroundColor: 'transparent'
                    }}>
                        <Containers id='g3-header' className='container-fluid' style={{
                            backgroundImage: `url('${modal}')`
                        }}>
                            
                            <Item>
                                <Col xs={12} md={4} className='m-2 m-md-0 '>
                                    <img className='img-fluid w-100 p-2'
                                        src={gambar(openlogo)} data-aos="fade-left" />
                                </Col>
                            </Item>
                            <Item>
                               
                                    <h2 className={`col-md-4 roboto-slab text-center pt-3 pt-sm-3`} style={{ marginTop: '0' }}>
                                      
                                        Kepada Yth :<br /> {query ? query : ''} <br /></h2>
                              
                               </Item>
                            <Row className='justify-content-center'>
                                <div onClick={() => { this.play() }}

                                    className={`col-md-4 button btn roboto-slab text-center ${hide ? 'show' : 'hide'}`}
                                    style={{ marginTop: 0, color: 'white' }}>
                                    Open Invitation
                            </div>
                            </Row>
                        </Containers>

                        <div className={hide ? 'd-none' : 'd-block'}>
                            <div id="top" style={{ backgroundColor: 'transparent' }}>
                                <Container fluid style={{ backgroundColor:'#FCF9F6',backgroundSize:'100% ' }} className="py-3 px-0">
                                   
                                        <Item>
                                            <h2 data-aos="fade-down" style={{ color: 'black' }}> بِسْمِ اللَّهِ الرَّحْمَنِ الرَّحِيم</h2>
                                        </Item>
                                        <p className="text-center py-3" style={{ color: 'black', fontSize: '14px' }} data-aos="fade-up">
                                            Assalamu'alaikum Warahmatullahi Wabarakatu
                                            Dengan memohon rahmat serta ridho dari Allah
                                            Subhanahu WaTa'ala.Bersama ini izinkan kami
                                            memohon doa restu bapak/ibu, saudara/i sekalian.<br />
                                            Insya Allah kami akan menikahkan Anak kami:
                                        </p>
                                        <table className="w-100">
                                            <tbody>
                                                <tr className="row">
                                                    <td className="col-5 p-0" data-aos="fade-up">
                                                    <img src={pw(id,"ce.svg")} className="w-100 img-fluid"/>
                                                    </td>
                                                    <td className="col-7 align-midle p-0">
                                                    <p className="text-center w-100 px-2"
                                                style={{
                                                    fontFamily: 'Sacramento',
                                                    fontSize: '32px', color: 'black',
                                                }}>
                                                {lengkap_ce}
                                            </p>
                                            <p className="text-center" style={{ fontSize: '14px', color: 'black' }}>
                                                <b>Putri dari:<br /></b>
                                                {bapak_ce}&<br />
                                                {ibu_ce}
                                            </p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td colSpan="2" className="text-center" data-aos="fade-up"> 
                                                    <img src={pw(id,"and.png")} className="col-8 col-md-4"></img>
                                                    </td>
                                                </tr>
                                                <tr className="row">
                                                    
                                                    <td className="col-7 align-midle p-0" data-aos="fade-up">
                                                    <p className="text-center w-100"
                                                style={{
                                                    fontFamily: 'Sacramento',
                                                    fontSize: '32px', color: 'black',
                                                }}>
                                                {lengkap_co}
                                            </p>
                                            <p className="text-center px-2" style={{ fontSize: '14px', color: 'black' }}>
                                                <b>Putra dari:<br /></b>
                                                {bapak_co} &<br />
                                                {ibu_co}
                                            </p>
                                                    </td>
                                                    <td className="col-5 p-0">
                                                    <img src={pw(id,"co.svg")} className="w-100 img-fluid"/>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                           

                                        
                                    </Container>

                               
                                <Container fluid style={{ backgroundColor: '#9e462f', color: 'white' }} className="py-3">
                                    <Container style={{position:'relative'}}>
                                        {/* <Col xs={4} style={{position:'absolute',top:0,left:0}}>
                                            <img src={pw("mela-wahyu","leaf.png")}/>
                                        </Col>
                                        <Col xs={4} style={{position:'absolute',top:0,right:0}}>
                                            <img src={pw("mela-wahyu","leaf.png")} className="mirror"/>
                                        </Col> */}

                                        <Item>
                                            <table class="table table-borderless" data-aos="fade-up">
                                                <tbody>
                                                    <tr>
                                                    
                                                        <td style={{
                                                            color: 'white', fontSize: '64px',
                                                            fontWeight: 700
                                                        }} className="align-middle text-right"
                                                            data-aos="fade-right">
                                                            07
                                                        </td>
                                                       
                                                        <td style={{ color: 'white', fontSize: '20px' }} className="align-middle"
                                                            data-aos="fade-left">
                                                            Februari <br />
                                                            <span style={{ fontSize: '28px' }}>2021</span>
                                                        </td>
                                                        
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </Item>
                                        <Item>
                                        <Col xs={6} sm={2}>
                                            <img src={pw(id, "daun.png")} data-aos="zoom-in" data-aos-duration="1000" className='img-fluid w-100' />
                                        </Col>
                                    </Item>
                                        <Row>
                                        <Col xs={12}>
                                        <p className="text-center w-100 mb-0" data-aos="fade-up"
                                            style={{
                                                color: 'white', fontSize: '28px', fontWeight: 700,
                                                fontFamily: 'PT Sans Narrow, sans-serif'
                                            }}>
                                            <b>Akad Nikah</b> <br />
                                        </p>
                                        <p className="w-100 text-center" style={{ color: 'white',fontSize:'16px' }} data-aos="fade-up">
                                        7.30-09.30 WIB
                                        </p>
                                        </Col>
                                        
                                        <Col xs={12}>
                                        <p className="text-center w-100 mb-0" data-aos="fade-up"
                                            style={{
                                                color: 'white', fontSize: '28px', fontWeight: 700,
                                                fontFamily: 'PT Sans Narrow, sans-serif'
                                            }}>
                                            <b>Resepsi</b> <br />
                                        </p>
                                        <p className="w-100 text-center" style={{ color: 'white',fontSize:'16px' }} data-aos="fade-up">
                                        11.00 - 13.30 WIB
                                            
                                        </p>
                                        </Col>
                                        </Row>
                                       
                                        <p className="w-100 text-center" style={{ color: 'white',fontSize:'16px' }} data-aos="fade-up">
                                        <b>Hotel Royal Tulip Gunung Geulis</b><br/>
                                         Jl. Pasir Angin, Nagrak, Kec. Sukaraja, Bogor, Jawa Barat 16710
                                            
                                        </p>
                                        <p className="text-center py-2"
                                                                    style={{
                                                                        fontFamily: 'PT Sans Narrow, sans-serif',
                                                                        fontSize: '28px', color: 'white',
                                                                        fontWeight: 700
                                                                    }}>
                                                                   Google Maps
                                                                </p>
                                                                <Item>
                                        <div className="mapouter m-3"><div className="gmap_canvas text-center">
                                            <iframe width="400" height="300" id="gmap_canvas"
                                                src={'https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d3963.164963084266!2d106.8634558!3d-6.6264221!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2e69c62bccacaa0b%3A0x846cedd517aefa44!2sRoyal%20Tulip%20Gunung%20Geulis%20Resort%20and%20Golf!5e0!3m2!1sid!2sid!4v1610853567654!5m2!1sid!2sid'} frameborder="0" scrolling="no" marginheight="0" marginwidth="0"></iframe><a href="https://www.whatismyip-address.com/nordvpn-coupon/"></a></div>
                                        </div>
                                    </Item>
                                    <Container className="py-3">
                                    <Item>
                                        <Col xs={12} md={6}>
                                            <img src={pw("asset","covid-white.svg")} className="w-100 img-fluid" />
                                        </Col>
                                    </Item>
                                </Container>
                                        
                                    </Container>


                                </Container>
                                <Container fluid style={{ backgroundImage:`url('${pw(id,"bg.jfif")}')`,
                                color:'white',
                                backgroundSize:'100%'}}>
                                    <Container className="py-3">
                                        <p className="w-100 text-center"
                                            style={{ fontFamily: 'Merienda, cursive', 
                                            fontWeight: 700, fontSize: '36px' }}>
                                            Our Story
                                        </p>
                                        {
                                            [
                                                {
                                                    gambar: pw(id,"story/1.jpg"),
                                                    judul: '2015',
                                                    content: (<>
                                                        <b>2015.</b><br/>
PNJ.<br/>
Dimataku dia senior pertama yang terlihat berbeda.<br/>
Ya, caranya berpenampilan.<br/>
Seorang laki-laki dengan celana maroon dan berkasut bootsnya. <br/>
<br/>
<b>2016</b> <br/>
Ujian mekanika hanya sejauh malam.<br/>
Keterbatasanku dan kawan-kawanku akan mata kuliah ini membawa kami pada titik kebingungan. <br/>
<br/>
Yang ada di benakku..<br/>
Hanya dia...<br/>
Si celana merah dan boots.<br/>
Bang Fathon, panggilan kami dulu para maba lugu kepada si senior peraih nilai A di Mekanika. <br/>
<br/>
Kawan-kawan dan aku sepakat bahwa dia adalah orang yang harus kami tuju. Namun tak kusangka mereka mempercayakan aku untuk memulai pertanyaan padanya. <br/>
<br/>
Pertanyaanku di sambut. <br/>
<br/>
Itulah kali pertama aku menghubunginya. <br/>
<br/>
<b>2017</b><br/>
<br/>
Tak disangka kebingunganku akan mata kuliah dapat mendekatkan aku dan dia. Memulai untuk menjaga satu sama lain. <br/>
<br/>
Mengubah Aku dan Kamu menjadi Kita.
                                                        </>)
                                                },
                                                {
                                                    gambar: pw(id,"story/2.jpg"),
                                                    judul: '2015',
                                                    content: (<>
                                                        <b>2018 - 2019</b><br/>
Jakarta - Manado<br/>
Long Distance Relationship. <br/>
<br/>
Tidak mudah,<br/>
Kita yang terbiasa bersama, kini terpisah antar pulau. <br/>
<br/>
Tapi bukan masalah,<br/>
sejak saat itu kita mengerti arti sesungguhnya dari Rindu, Doa dan Percaya. <br/>
<br/>
<b>2020</b><br/>
Tahun ketiga hubungan jarak jauh, tetap dengan komunikasi terbatas karna kesibukan masing-masing.<br/>
Tak juga jadi masalah,<br/>
Sebab kita sudah paham bagaimana menghargai waktu singkat,<br/>
Ketika akhirnya bisa sejenak bersama sebelum terpaksa melanjutkan kesibukan<br/>
<br/>
2021<br/>
Ternyata ini tahun yang kita tunggu setelah 5 tahun perjalanan. <br/>
<br/>
~ <br/>
<br/>
Sejauh mata memandang,<br/>
Dia masih menjadi satu-satunya senior yang menarik perhatianku. <br/>
<br/>
Sejauh aku pergi,<br/>
Dia masih menjadi tempat aku pulang dan mengadu. <br/>
<br/>
Sejauh WIB-WITA,<br/>
Dia masih menjadi orang yang akan aku tunggu untuk bisa menghabiskan waktu <br/>
<br/>
~ <br/>
<br/>
Untuk tahun-tahun berikutnya, kita siap ya mas Fathon Darmawanku?<br/>
~ Farah ~

                                                        </>)
                                                },
                                                {
                                                    gambar: pw(id,"story/3.jpg"),
                                                    judul: '2015',
                                                    content: (<>
                                                        <b>Tahun 2015.</b><br/>
Awalnya biasa saja..<br/>
Belum ada yang mengetuk hati ini sampai akhirnya dia datang untuk memenuhi tugas ospek yang aku berikan dengan wajah terpaksanya. <br/>
<br/>
Namun justru di situ mulai ada rasa tertarik. <br/>
<br/>
Ku beranikan diri mendekati<br/>
Tetapi dia menghindar menjauhi <br/>
<br/>
Sinyal penolakan ku terima<br/>
Ku sesali keberanianku dan memilih mundur<br/>
Namun ajaib benar, dia yang mendekat. <br/>
<br/>
Berawal dari pertanyaan mata kuliah semata,<br/>
Berlanjut sampai obrolan-obrolan kecil yang hangat. <br/>
<br/>
Bahan pembicaran terus bermunculan,<br/>
Hingga akhirnya menyadari sudah jauh perjalanan.<br/>
Kami mulai mengenal kata hubungan,<br/>
Walau tanpa peresmian, kami yakin itu sebuah ikatan. 
                                                        </>)
                                                },
                                                {
                                                    gambar: pw(id,"story/4.jpg"),
                                                    judul: '2015',
                                                    content: (<>
                                                        Lima tahun bersama,<br/>
Bagiku dia selalu jadi sumber kebahagiaan.<br/>
Pemantik semangat di setiap lelahku.<br/>
Pemberi warna setelah badai melanda hariku. <br/>
<br/>
Tak lagi aku takut menghadapi angin kencang maupun hujan deras<br/>
Karena ku yakin dia pasti akan selalu hadir setelah mereka terlewati <br/>
<br/>
Tengkar, tawa<br/>
Tangis, canda<br/>
Semua itu menyenangkan karena kami bersama <br/>
<br/>
Kebersamaan yang ingin kami lanjutkan,<br/>
Dengan merajut masa depan. <br/>
<br/>
Saling mengerti<br/>
Saling mengasihi<br/>
Saling mempercayai.<br/>
Tak ada yang perlu diragukan lagi. <br/>
<br/>
Siap melangkah bersamaku, My Rainbow After The Storm?<br/>
<br/>
~ Fathon ~
                                                        </>)
                                                },
                                                {
                                                    gambar: pw(id,"story/5.jpg"),
                                                    judul: '2015',
                                                    content:''}
                                                // { 
                                                
                                                // ,
                                                // pw(id,"DSCF0177.jpg"),
                                                    
                                                
                                            ].map((v, i) => {
                                                return (
                                                    <Row className="py-2">
                                                        <Col md={6} xs={12} data-aos="fade-right" className={`px-2 order-md-${i % 2 ? 2 : 1}`}>
                                                            <img src={v.gambar} className="w-100 img-flui"/>
                                                        </Col>
                                                        <Col md={6} xs={12}
                                                            data-aos="fade-right" className={`px-2 order-md-${i % 2 ? 1 : 2}`}
                                                            style={{ color: 'white' }}>
                                                            <p style={{ fontSize: '14px' }} className="text-center">
                                                                {v.content}
                                                            </p>
                                                        </Col>
                                                    </Row>
                                                )
                                            })
                                        }

                                    </Container>
                                </Container>
                                <Container fluid className="p-0"> 
                                    <img src={pw(id,"DSCF0110.jpg")} className="w-100 img-fluid"/>

                                </Container>
                                <style jsx>
                                    {
                                        `
                                        #g3-header.wahai1 {
                                            background-image: url('${pw(id,"DSCF0124.jpg")}');
                                        }
                                        
                                        @media(min-width:900px) {
                                            #g3-header.wahai1 {
                                                background-image: url('https://demo.cloudimg.io/v7/${pw(id,"DSCF0124.jpg")}?force_format=webp&q=95&gravity=auto&func=fit&bg_img_fit=1&bg_opacity=0.75&w=720&h=480');
                                            }
                                        }
                                        `
                                    }
                                </style>
                                <Container fluid id='g3-header' className='position-relative p-0 wahai1' style={{
                                    Height: '75vh'
                                }}>
                                    <div style={{
                                        backgroundColor:'rgba(0,0,0,0.5)',
                                        color:'white',
                                        bottom:'10%'
                                    }}
                                    className="w-100 position-absolute">
                                        <p className="w-100 text-center" style={{fontSize:'14px'}} data-aos="fade-left" data-aos-duration="1000">
                                       
“And of His signs is that He created for you from yourselves mates that you may find tranquility in them; and He placed between you affection and mercy. Indeed in that are signs for a people who give thought.”
<br/>
<br/>

(Ar-Rum: 21)
                                        </p>
                                    </div>
                                </Container>
                                <style jsx>
                                    {
                                        `
                                        #g3-header.save {
                                            background-image: url('${pw(id,"DSCF7795.jpg")}');
                                        }
                                        
                                        @media(min-width:900px) {
                                            #g3-header.save {
                                                background-image: url('https://demo.cloudimg.io/v7/${pw(id,"DSCF7795.jpg")}?force_format=webp&q=95&gravity=auto&func=fit&bg_img_fit=1&bg_opacity=0.75&w=720&h=480');
                                            }
                                        }
                                        `
                                    }
                                </style>

                                <Container fluid id='g3-header' className='position-relative p-0 save' style={{
                                    height: '75vh'
                                }}>
                                    <p className="w-100 text-center"
                                        style={{ fontFamily: 'Merienda, cursive', 
                                        fontWeight: 700, fontSize: '36px' }}>
                                        07 Februari 2021
                                        </p>
                                    {/* <Item>
                                        <Col xs={6} md={3}>
                                            <img src={pw(id, "save-the-date.gif")}
                                                className="w-100 img-fluid" />
                                        </Col>
                                    </Item> */}
                                    <div className="position-absolute w-100"
                                    style={{
                                        bottom:"10%",
                                        left:0
                                    }}>
                                    <Timer cmain={'transparent'} waktunikah={waktunikah} />

                                    </div>

                                </Container>
                                <Container fluid style={{ backgroundImage:`url('${pw(id,"DSCF0227.jpg")}')`,
                                backgroundSize:'cover',
                                backgroundPosition:'center' ,                               
                                color: 'white' }} className="py-3">
                                     <Slider slide={
                                                        [
                                                            pw(id,"prewed/1a.jpg"),
                                                            pw(id,"prewed/2a.jpg"),
                                                            pw(id,"prewed/3a.jpg"),
                                                            pw(id,"prewed/4a.jpg"),
                                                            pw(id,"prewed/5a.jpg"),
                                                    ]} />
                                    
                                    <Item>
                                        <div className="pt-5">
                                               
                                        
                                   
                                    </div>
                                    </Item>
                                  
                                    </Container>
                                <Container fluid style={{backgroundColor:'#9E462F'}}>
                                    <Container>

                                        <p className="w-100 text-center py-3"
                                            style={{
                                                fontFamily: 'Merienda, cursive',
                                                fontWeight: 700, fontSize: '36px',
                                                fontStyle: 'italic',
                                                color:'white'
                                            }}>
                                            Our Cinematic Video
                                        </p>
                                        <div className="embed-responsive embed-responsive-4by3 mb-3 p-3">
                                            <iframe className="embed-responsive-item"
                                                src="https://www.youtube.com/embed/9Sb1-nL237A"></iframe>
                                        </div>
                                    </Container>


                                    <Container id='sectiongold58' >

                                        <div className='pt-3 mt-4 mt-lg-5 mb-lg-3'>
                                            <Item>
                                                <Col xs={4} lg={2}>
                                                    <img data-aos="zoom-in" data-aos-duration="1000" src={bunga6} className='img-fluid w-100' />
                                                </Col>
                                            </Item>
                                            <Item>
                                                <div className='col-10 col-lg-6 kotak pb-4 pt-4' data-aos="left-left" data-aos-duration="1000">
                                                    <Item>
                                                        <h1 className="w-100 text-center" style={{
                                                            fontFamily: '"Marck Script", cursive',
                                                            color: cmain
                                                        }}>
                                                            Doa untuk Kedua Mempelai
                                                        </h1>
                                                    </Item>
                                                    <Item>
                                                        <form className="col-12 w-100">
                                                            <input ref={this.nama} type='text' className="col-12 w-100 text-center" placeholder="Name" name='nama' />
                                                            <input ref={this.pesan} type='text-area' className="col-12 w-100 text-center bigger" placeholder="Message" name='pesan' />
                                                            <Item>
                            <div id="formradio">
                              <div class="custom_radio row justify-content-center">
                                <div onClick={() => {
                                  this.setState({ hadir: true })
                                }
                                }>
                                  <input type="radio" id="featured-1" name="featured" checked={hadir ? true : false} />
                                  <label for="featured-1">Hadir</label>
                                </div>
                                <div onClick={() => {
                                  this.setState({ hadir: false })
                                }
                                } className="pl-5">
                                  <input type="radio" id="featured-2" name="featured" checked={hadir ? false : true} />
                                  <label for="featured-2"

                                  >Tidak Hadir</label>
                                </div>

                              </div>
                            </div>
                          </Item>
                                                            {
                                                                sesi == 1 || sesi == 2 ? (
                                                                    <Item>
                                                                        <div id="formradio">
                                                                            <div class="custom_radio row justify-content-center">
                                                                                <div onClick={() => {
                                                                                    this.setState({ hadir: true })
                                                                                }
                                                                                }>
                                                                                    <input type="radio" id="featured-1" name="featured" checked={hadir ? true : false} />
                                                                                    <label for="featured-1">Hadir</label>
                                                                                </div>
                                                                                <div onClick={() => {
                                                                                    this.setState({ hadir: false })
                                                                                }
                                                                                } className="pl-5">
                                                                                    <input type="radio" id="featured-2" name="featured" checked={hadir ? false : true} />
                                                                                    <label for="featured-2"

                                                                                    >Tidak Hadir</label>
                                                                                </div>
                                                                                {!hadir ? false : (
                                                                                    <>  <Alert variant='dark col-12 mr-4 '>

                                                                                        {
                                                                                            sesi == 1 ? (<div onClick={() => {
                                                                                                this.setState({ sesi: 1 })
                                                                                            }
                                                                                            }
                                                                                                className="pl-5">
                                                                                                <input type="radio" id="featured-3" name="sesi" checked={sesi == 1 ? true : false} />
                                                                                                <label for="featured-3">

                                                                                                    18.30 - 19.30 WIB
                                                                                    </label>
                                                                                            </div>) : (
                                                                                                    <div onClick={() => {
                                                                                                        this.setState({ sesi: 2 })
                                                                                                    }
                                                                                                    } className="pl-5">
                                                                                                        <input type="radio" id="featured-4" name="sesi" checked={sesi == 2 ? true : false} />
                                                                                                        <label for="featured-4"

                                                                                                        >19.30 - 20.30 WIB</label>
                                                                                                    </div>
                                                                                                )
                                                                                        }
                                                                                    </Alert>



                                                                                    </>

                                                                                )}

                                                                            </div>
                                                                        </div>
                                                                    </Item>


                                                                ) : false
                                                            }
                                                            <Item>
                                                                <Col xs={12} className=''>
                                                                    {
                                                                        submitted == true ? (
                                                                            <Alert variant='success' style={{ fontSize: '16px' }}>
                                                                                Pesan anda sudah disampaikan
                                                                            </Alert>) : (submitted === false ? (
                                                                                <Alert variant='danger' style={{ fontSize: '16px' }}>
                                                                                    {
                                                                                        err.map(val => {
                                                                                            return (
                                                                                                <li>{val}</li>
                                                                                            )
                                                                                        })
                                                                                    }

                                                                                </Alert>
                                                                            ) : false)
                                                                    }

                                                                </Col>
                                                            </Item>
                                                            <Item>
                                                                <div className='col-6 button rounded btn'
                                                                    onClick={() => this.handleSubmit()} style={{ backgroundColor: cmain, color: 'white' }} no> Kirim </div>
                                                            </Item>
                                                        </form>
                                                    </Item>
                                                </div>
                                            </Item>
                                        </div>
                                    </Container>
                                    <Container>
                                        <Row style={{ maxHeight: '50vh', overflowY: 'scroll', width: '100%' }} className="scroller">
                                            {comment.map((v, i) => {
                                                console.log(v)
                                                return (
                                                    <Row className="w-100 text-center mx-auto">
                                                        <Col></Col>
                                                        <Col xs={12} md={6} className="py-2">
                                                            <p className="w-100 text-left my-auto" 
                                                            style={{ fontSize: '18px',color:'white' }}>
                                                                <b>{decodeURI(v.dari)}</b><br />
                                                                {decodeURI(v.pesan)}<br />
                                                                <span  style={{ fontSize: '14px'}}>
                                                                {moment(v.createdAt).startOf('hour').fromNow()}
                                                                </span>
                                                            </p>
                                                        </Col>
                                                        <Col></Col>
                                                    </Row>
                                                )
                                            })
                                            }
                                        </Row>
                                    </Container>
                                    <Container className="py-3">
                                    <Slider slide={
                                                        [gambar(pw(id, "lamaran/1b.jpg"), 95, 'auto&func=fit&bg_img_fit=1&bg_opacity=0.75&w=1440&h=720'),
                                                        gambar(pw(id, "lamaran/2b.jpg"), 95, 'auto&func=fit&bg_img_fit=1&bg_opacity=0.75&w=1440&h=720'),
                                                        gambar(pw(id, "lamaran/3b.jpg"), 95, 'auto&func=fit&bg_img_fit=1&bg_opacity=0.75&w=1440&h=720'),
                                                        gambar(pw(id, "lamaran/4b.JPG"), 95, 'auto&func=fit&bg_img_fit=1&bg_opacity=0.75&w=1440&h=720')
                                                           
                                                    ]} />
                                    </Container>
                                                                 

                                    
                                </Container>
                               <Container fluid style={{color:'white', backgroundColor:'#821543'}}>
                               <Container>
                                <Item>
                                    <p className='text-center pt-3' style={{fontSize:'14px'}}>
                                        Yang Bersyukur & Berbahagia:<br/>
                                        {bapak_ce} <br/>& {ibu_ce}<br/><br/>
                                        {bapak_co} <br/>& {ibu_co}<br/>

                                    </p>
                                </Item>
                                <Foot ig={pw("asset", "logoig-white.svg")} />
                                </Container>
                               </Container>



                            </div>
                        </div>
                    </div>
                </div>

            </>


        )
    }
}

